<template>
    <div class="pbyDiv">
        <el-row class="pbyRow1">
            <el-col :span=6 class="pbyConditionDiv" >
                <span v-if="type=='brand'">按品牌查询</span>
                 <div v-if="type=='category'">按类型查询</div>
            </el-col>
            <el-col :span=18 > 
                <el-radio-group v-model="searchRadio">
                    <el-radio-button  v-for="(item,i) in radioList" :key=i :label="item"></el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-row class="pbyRow" :gutter="40"  v-for="(item,i) in newList" :key="i">
          <el-col :span=6>
              <img :src="item.imgUrl"/>
          </el-col>
          <el-col :span=18 class="pbyRowLeft">
              <div class="pbyDiv2">{{item.title}}</div>
              <div class="pbyDiv3">{{item.content}}</div>
              <div class="pbyMore" @click="moreClick(item)">MORE ></div>
          </el-col>
      </el-row>
    </div>
</template>
<script>
export default {
    name:"productByCondition",
    data(){
        return{
            type:"brand",
            temp:{imgUrl:require("../../static/product_img1.png"),
                title:"Wise大数据平台",
                content:`集数据建模与ETL设计于一体，在建模的同时同步完成ETL流程设计，提供一站式数据处理服务集数据建模与ETL设计于一体，
                在建模的同时同步完成ETL流程设计，提供一站式数据处理服务在建模的同时同步完成ETL流程设计，提供一站式数据处理服务`,
            },
            count:0,
            newList:[],
            radioList:[],
            searchRadio:"微软"
        }
    },
    methods:{
        init(){
            let paramType= this.$route.params.type
            this.type=paramType
            this.getData(paramType)
        },
        getData(type){
            let num=0;
            if(type=="brand"){
                this.count=2
                num=2
                 this.radioList=["微软","红帽","金山","巴卡斯基","浪潮","Adobe","亚信安全","绿盟科技","奇安信"]
                this.searchRadio="微软"
            }else if(type=="category"){
                this.count=3
                num=3
                this.radioList=["金融类型","教育类型","科技类型","农业类型","智能类型"]
                this.searchRadio="金融类型"
            }
            let tempList=[]
            for(let i=0;i<num;i++){
                let item={
                    imgUrl:this.temp.imgUrl,
                    title:i+"_"+this.temp.title,
                    content:this.temp.content+"......",
                    id:i+1
                }
                tempList.push(item)
            }
            this.newList=tempList
        },
         moreClick(row){
           this.$router.push({
               name:"productInfo",
               query:{
                   name:row.title,
                   type:"productByCondition",
                   page:this.type
               }
           })
       }

    },
    created(){
        this.init()
    },
     watch: {
        $route() {
            this.init()  //监听路由变化
        }
    },
}
</script>
<style>
.pbyDiv{
    width:100%;
    padding:60px 0;
}
.pbyRow{
    padding:20px 0;
    border:1px solid #DBE3EF;
    position:relative;
}

.pbyRow .pbyDiv1{
    font-size:14px;
    line-height: 24px;
}
.pbyRow .pbyRowRight{
    text-align:center
}

.pbyRow .pbyMore
{
    font-size:14px;
    color:#3C6AFB;
    margin-top: 20px;
    cursor:pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: bold;
}
.pbyRowLeft .pbyDiv2{
    height: 32px;
    font-size: 18px;
    color: #404756;
    line-height: 32px;
}
.pbyRowLeft .pbyDiv3{
    height: 26px;
    font-size: 14px;
    color: #848484;
    line-height: 26px;
    margin-top:20px

}
.pbyConditionDiv{
    margin-top:20px
}
.pbyConditionDiv span{
    height: 16px;
    font-size: 16px;
    color: #404756;
    line-height: 16px;
}
.pbyRow1{
    margin-bottom:50px;

}
</style>
